import * as R from 'ramda';
import React, { useCallback, useEffect, useMemo } from 'react';

import { WidgetContainer, WidgetNumberMark } from 'components';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import {
  ConstructorFormSection,
  QuizConstructorModal,
} from 'features/project/Constructor/subfeatures';
import { PrimaryStateUnit } from 'utils/State';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { materialKeyToMaterialConstructorWidget } from '../materials';
import { CachedInstance, MaterialKey } from '../types';
import { WidgetContext } from './Context';
import * as ControlsBar from './ControlsBar';
import * as ForMaterialContent from './ForMaterial';
import * as ForMaterialSelectionContent from './ForMaterialSelection';
import * as Header from './Header';

type Props = {
  cachedInstance: CachedInstance;
  index: number;
  instancesUnit: PrimaryStateUnit<CachedInstance[]>;
};

function ConstructorWidget({ cachedInstance, index, instancesUnit }: Props) {
  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const handleWidgetDelete = useCallback(() => {
    instancesUnit.setState(prev => {
      return R.without([cachedInstance], prev);
    });
    saveProject();
  }, [cachedInstance, instancesUnit, saveProject]);

  const handleMoveWidgetPrev = useCallback(() => {
    instancesUnit.setState(prev => {
      const index = prev.findIndex(x => x === cachedInstance);
      return R.move(index, index - 1, prev);
    });
    saveProject();
  }, [cachedInstance, instancesUnit, saveProject]);

  const handleMoveWidgetNext = useCallback(() => {
    instancesUnit.setState(prev => {
      const index = prev.findIndex(x => x === cachedInstance);
      return R.move(index, index + 1, prev);
    });
    saveProject();
  }, [cachedInstance, instancesUnit, saveProject]);

  const Title = useMemo(
    () =>
      WidgetNumberMark.makeComponent({
        value: index + 1,
      }),
    [index],
  );

  const handleMaterialKeyChange = useCallback(
    (kind: MaterialKey) => {
      if (kind === 'test') {
        QuizConstructorModal.isOpenUnit.setState(true);
      }
      cachedInstance.activeMaterialKeyUnit.setState(kind);
    },
    [cachedInstance.activeMaterialKeyUnit],
  );

  const activeMaterialKey = cachedInstance.activeMaterialKeyUnit.useState();

  const stateInstance = cachedInstance.instancesCache[activeMaterialKey];

  useEffect(() => {
    return QuizConstructorModal.isOpenUnit.subscribe({
      name: 'quiz-modal-closed',
      callback: state => {
        if (!state && stateInstance.kind === 'test') {
          if (!stateInstance.quiz.getValue()) {
            instancesUnit.setState(prev => {
              return R.without([cachedInstance], prev);
            });
          }
          saveProject();
        }
      },
    });
  }, [cachedInstance, instancesUnit, saveProject, stateInstance]);

  return (
    <ConstructorFormSection.Component>
      <WidgetContext.Provider
        onDelete={handleWidgetDelete}
        onMovePrev={handleMoveWidgetPrev}
        onMoveNext={handleMoveWidgetNext}
        index={index}
        stateInstance={stateInstance}
        constructorWidget={
          materialKeyToMaterialConstructorWidget[activeMaterialKey]
        }
      >
        <Header.DependenciesContext.Provider
          onMaterialKeyChange={handleMaterialKeyChange}
        >
          {(() => {
            if (activeMaterialKey === 'notSelected') {
              return (
                <ForMaterialSelectionContent.Component
                  Title={Title}
                  cachedInstance={cachedInstance}
                />
              );
            }

            return (
              <WidgetContainer.Component
                ControlsBar={ControlsBar.Component}
                Title={Title}
                Content={ForMaterialContent.Component}
                Header={Header.Component}
              />
            );
          })()}
        </Header.DependenciesContext.Provider>
      </WidgetContext.Provider>
    </ConstructorFormSection.Component>
  );
}

export const Component = React.memo(ConstructorWidget);
