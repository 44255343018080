import { FilterQuery } from 'mongodb';

import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = {};

type QueryInputData = {
  filter?: FilterQuery<M.Batch | M.Article>;
  sort?: {
    property: keyof M.Batch | keyof M.Article;
    direction: 'ASC' | 'DESC';
  };
};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  articles?: (M.Batch | M.Article)[];
};

type OutputData = {
  articles: (M.Batch | M.Article)[];
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    articles: data.articles || [],
  };
}

export const list = makeService<InputData, APIOutputData, OutputData>(
  (inputData, convertToSearchParams) => {
    const { filter, sort } = inputData;

    return `article/?${convertToSearchParams({
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
    })}`;
  },
  'api-v2-get',
  false,
  extractResponse,
);
