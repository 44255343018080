import React, { useCallback, useEffect } from 'react';

import { routeTree } from 'pages/routeTree';
import { API, I18n, Routing } from 'services';
import * as M from 'types/serverModels';

import i18nData from '../i18n.json';
import { ConfirmActionModal } from '../subfeatures';

export type Props = Pick<ConfirmActionModal.Props, 'isOpenUnit'> & {
  project: M.Project;
  callStateUnit: API.CallStateUnitOf<typeof API.services.project.del>;
};

function ConfirmModal({ project, isOpenUnit, callStateUnit }: Props) {
  const text = I18n.useText(i18nData).confirmModal;
  const t = I18n.useGetMultilingProjectTranslation();

  const callState = callStateUnit.useState();

  const call = API.services.project.del.useCall(callStateUnit);

  const handleYesClick = useCallback(() => {
    call({ uuid: project.uuid });
  }, [project.uuid, call]);

  useEffect(
    () =>
      callStateUnit.subscribe({
        name: 'states-updater',
        callback: callState => {
          switch (callState.kind) {
            case 'successful': {
              const lang = I18n.activeLangStateUnit.getState();

              Routing.getHistory()?.push(
                routeTree.LANG['personal-account']['my-projects'].getPath({
                  routeParams: { LANG: lang },
                }),
              );
            }
          }
        },
      }),
    [callStateUnit, isOpenUnit],
  );

  return (
    <ConfirmActionModal.Component
      isOpenUnit={isOpenUnit}
      callStateUnit={callStateUnit}
      onYesClick={handleYesClick}
    >
      {text.text.replace('#{title}', t(project.title))}
      {API.renderCallState(callState, {
        successful: () => null,
        pending: () => null,
      })}
    </ConfirmActionModal.Component>
  );
}

export const Component = React.memo(ConfirmModal);
