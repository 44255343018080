import {
  createRequiredContext,
  useRequiredContext,
} from 'utils/react/RequiredContext';

import { QuestionsConfigContext } from '../../QuestionsConfigContext';
import {
  CachedQuestionInstance,
  QuestionConstructorWidget,
  WidgetPath,
} from '../../types';

export type QuestionWidgetContextData = {
  path: WidgetPath;
  cachedInstance: CachedQuestionInstance;
  constructorWidget: QuestionConstructorWidget<any, any, any>;
};

export const QuestionWidgetContext =
  createRequiredContext<QuestionWidgetContextData>();

type Props = { path: WidgetPath; cachedInstance: CachedQuestionInstance };

export function QuestionWidgetContextProvider({
  path,
  cachedInstance,
  children,
}: React.PropsWithChildren<Props>) {
  const questionKey = cachedInstance.activeQuestionKeyUnit.useState();
  const { questionKeyToConstructorWidget } = useRequiredContext(
    QuestionsConfigContext,
  );

  const constructorWidget = questionKeyToConstructorWidget[questionKey];

  return (
    <QuestionWidgetContext.Provider
      path={path}
      cachedInstance={cachedInstance}
      constructorWidget={constructorWidget}
    >
      {children}
    </QuestionWidgetContext.Provider>
  );
}
