import React from 'react';

import { QUESTION_VISIBILITY } from 'shared/constants';
import { userStateUnit } from 'shared/stateUnits';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { nonNull } from 'utils/validators';

import { questionIsRequired } from '../i18nSharedReferences';

export const makeState = (
  question: M.MapQuestion,
  initialValue: M.Location | null = null,
  withUserLocationByDefault: boolean = true,
) => {
  const userState = userStateUnit.getState();

  const userLocation: M.Location =
    userState.kind === 'loaded'
      ? [userState.user.location.longitude, userState.user.location.latitude]
      : [0, 0];

  const presetLocation =
    !withUserLocationByDefault || userLocation.every(x => x === 0)
      ? null
      : userLocation;

  const value = !question.optional
    ? initialValue || presetLocation
    : initialValue;

  const formElementState = makeFormElementState<M.Location | null>(
    null,
    !question.optional ? [nonNull(questionIsRequired)] : [],
  );
  if (question.visibility !== QUESTION_VISIBILITY.disabled) {
    formElementState.units.value.setState(value);
  }

  return formElementState;
};

export const useState = (
  question: M.MapQuestion,
  initialValue?: M.Location | null,
  withUserLocationByDefault?: boolean,
) => {
  const [state] = React.useState(() =>
    makeState(question, initialValue, withUserLocationByDefault),
  );
  return state;
};
