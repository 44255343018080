import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = {
  uuid_or_code: M.Webinar['code'] | M.Webinar['uuid'];
};

type QueryInputData = {};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  webinar?: M.Webinar;
};

type OutputData = {
  webinar: M.Webinar;
};

function extractResponse(data: APIOutputData): OutputData {
  if (!data.webinar) {
    throw Error('undefined webinar');
  }

  return {
    webinar: data.webinar,
  };
}

export const get = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket, uuid_or_code }, convertToSearchParams) =>
    `webinar/${uuid_or_code}/?${convertToSearchParams({ ticket })}`,
  'api-v2-get',
  false,
  extractResponse,
);
