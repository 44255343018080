import React, { useEffect } from 'react';

import { Page, I18n, Routing, API } from 'services';
import { userStateUnit } from 'shared/stateUnits';
import * as M from 'types/serverModels';

import { routeTree } from '../../routeTree';
import * as features from './features';

type Props = {
  group: M.Group;
  getGroup: () => void;
  useGetGroupCallState: () => API.CallState<M.ServerGroup>;
};

function Settings({ group, getGroup, useGetGroupCallState }: Props) {
  const userCallState = userStateUnit.useState();
  const lang = I18n.activeLangStateUnit.useState();

  useEffect(() => {
    const history = Routing.getHistory();

    if (
      userCallState.kind === 'loaded' &&
      group &&
      group.leaders &&
      process.env.BUILD_TARGET === 'client'
    ) {
      const userIsLeader = Object.keys(group.leaders).some(
        leader => userCallState.user.login === leader,
      );
      if (!userIsLeader) {
        history?.replace(
          routeTree.LANG.group.GROUP.about.getPath({
            routeParams: {
              LANG: lang,
              GROUP: group.uuid!,
            },
          }),
        );
      }
    }

    if (userCallState.kind === 'null') {
      history?.replace(
        routeTree.LANG.group.GROUP.about.getPath({
          routeParams: {
            LANG: lang,
            GROUP: group.uuid!,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, lang, userCallState.kind]);

  return (
    <features.Settings.Component
      group={group}
      getGroup={getGroup}
      useGetGroupCallState={useGetGroupCallState}
    />
  );
}

export const path = routeTree.LANG.group.GROUP.settings.getPath();

export const Component = Page.makePage({
  path: routeTree.LANG.group.GROUP.settings.getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(Settings) as typeof Settings,
  features: Object.values(features).map(x => x.feature),
});
