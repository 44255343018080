import React from 'react';

import { useRequiredContext } from 'utils/react/RequiredContext';

import { WidgetContext } from '../Context';
import * as EditContent from './EditContent';
import * as PreviewContent from './PreviewContent';

type Props = {};

function Content({}: Props) {
  const { index, constructorWidget, stateInstance } =
    useRequiredContext(WidgetContext);

  const mode = stateInstance.mode.useState();

  switch (mode) {
    case 'edit':
      return (
        <EditContent.Component
          index={index}
          constructorWidget={constructorWidget}
          stateInstance={stateInstance}
        />
      );
    case 'preview':
      return (
        <PreviewContent.Component
          constructorWidget={constructorWidget}
          stateInstance={stateInstance}
        />
      );
  }
}

export const Component = React.memo(Content);
