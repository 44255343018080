import { useCallback } from 'react';

import {
  createRequiredContext,
  useRequiredContext,
} from 'utils/react/RequiredContext';

type OriginContextData = {
  originURL: URL;
};

export const OriginContext = createRequiredContext<OriginContextData>();

export function useOriginURL() {
  const { originURL } = useRequiredContext(OriginContext);

  return originURL;
}

export function useGetOriginURL() {
  const { originURL } = useRequiredContext(OriginContext);

  return useCallback(() => {
    return originURL;
  }, [originURL]);
}
