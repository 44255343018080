import { useCallback, useEffect } from 'react';

import { Button } from 'components';
import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import i18nData from 'features/project/Constructor/i18n.json';
import {
  List,
  UserTableCellView,
  ConfirmActionOnUserModal,
  UnknownErrorModal,
} from 'features/project/Constructor/subfeatures';
import { API, I18n } from 'services';
import { userStateUnit } from 'shared/stateUnits';
import * as M from 'types/serverModels';
import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

const b = block('authors-list');

export const makeColumns = (
  usersUnit: PrimaryStateUnit<M.UserXShort[]>,
): Array<List.Column<M.UserXShort>> => [
  {
    cell: {
      align: 'left',
      Component: ({ entry }) => (
        <UserTableCellView.Component userData={entry} />
      ),
    },
  },

  {
    cell: {
      align: 'right',
      Component: ({ entry }) => {
        const text = I18n.useText(i18nData).steps.access.sections.leadership;

        const userState = userStateUnit.useState();

        const isConfirmModalOpenUnit = ConfirmActionOnUserModal.useIsOpenUnit();
        const isErrorModalOpenUnit = UnknownErrorModal.useIsOpenUnit();

        const { mode, getProjectUUID } = useRequiredContext(
          ConstructorConfigContext,
        );
        const callStateUnit = API.services.project.owner.del.useCallStateUnit();

        const call = API.services.project.owner.del.useCall(callStateUnit);
        const handleConfirmModalYesClick = useCallback(() => {
          const projectUUID = getProjectUUID();

          if (projectUUID === null) {
            throw Error('unexpected nullary project uuid');
          }

          call({ uuid: projectUUID, login: entry.login });
        }, [call, entry.login, getProjectUUID]);

        useEffect(() => {
          return callStateUnit.subscribe({
            name: 'users-state-updater',
            callback: state => {
              switch (state.kind) {
                case 'successful': {
                  usersUnit.setState(prev =>
                    prev.filter(x => x.login !== entry.login),
                  );

                  break;
                }
                case 'error': {
                  isErrorModalOpenUnit.setState(true);

                  break;
                }
              }
            },
          });
        }, [entry.login, callStateUnit, isErrorModalOpenUnit]);

        const handleClick = useCallback(() => {
          isConfirmModalOpenUnit.setState(true);
        }, [isConfirmModalOpenUnit]);

        if (
          mode === 'compact' ||
          userState.kind !== 'loaded' ||
          userState.user.login === entry.login
        ) {
          return null;
        }

        return (
          <div className={b('button-container')}>
            <Button.Component
              type="button"
              onClick={handleClick}
              variant="outlined"
              color="secondary"
              size="xs"
              callStateUnit={callStateUnit}
            >
              {text.buttons.expelButtonLabel}
            </Button.Component>
            <ConfirmActionOnUserModal.Component
              isOpenUnit={isConfirmModalOpenUnit}
              userData={entry}
              onYesClick={handleConfirmModalYesClick}
            >
              {text.modals.confirmExpel.text}
            </ConfirmActionOnUserModal.Component>
            <UnknownErrorModal.Component isOpenUnit={isErrorModalOpenUnit} />
          </div>
        );
      },
    },
  },
];
