import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = {
  uuid: M.Project['uuid'];
};

type QueryInputData = {};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  likes?: number;
};

type OutputData = number;

function extractResponse(data: APIOutputData): OutputData {
  return data.likes ?? -1;
}

export const put = makeService<InputData, APIOutputData, OutputData>(
  ({ uuid }) => `project/${uuid}/like/`,
  'api-v2-put',
  false,
  extractResponse,
);
