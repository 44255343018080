import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = {
  uuid: M.Project['uuid'];
};

type QueryInputData = {};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  users?: M.UserXShort[];
};

type OutputData = {
  users: M.UserXShort[];
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    users: data.users || [],
  };
}

export const list = makeService<InputData, APIOutputData, OutputData>(
  ({ uuid }) => `project/${uuid}/users/`,
  'api-v2-get',
  false,
  extractResponse,
);
