import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = {
  uuid: M.Project['uuid'];
  login: M.User['login'];
};

type QueryInputData = {};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  project?: M.Project;
};

type OutputData = {
  project: M.Project;
};

function extractResponse(data: APIOutputData): OutputData {
  if (!data.project) {
    throw Error('undefined project');
  }

  return {
    project: data.project,
  };
}

export const del = makeService<InputData, APIOutputData, OutputData>(
  ({ uuid, login }) => `project/${uuid}/owner/${login}/`,
  'api-v2-delete',
  false,
  extractResponse,
);
