import { makeFeature } from 'services/Feature';

import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'CourseViewMembers',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "course-view-members" */
      './Component'
    ),
  i18nData: null,
  i18nSharedReferences: null,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
  prefetched: true,
});

export type { Props } from './Component';

export const Component = feature.Component;
