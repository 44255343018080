import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = {
  uuid: M.Project['uuid'];
};

type QueryInputData = {};

type InputData = PathInputData & QueryInputData;

type APIOutputData = M.ProjectStat;

type OutputData = M.ProjectStat;

function extractResponse(data: APIOutputData): OutputData {
  return data;
}

export const get = makeService<InputData, APIOutputData, OutputData>(
  ({ uuid }) => `project/${uuid}/stat/`,
  'api-v2-get',
  false,
  extractResponse,
);
