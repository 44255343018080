import { FilterQuery } from 'mongodb';

import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = {};

type QueryInputData = {
  filter?: FilterQuery<M.Webinar>;
  sort?: { property: keyof M.Webinar; direction: 'ASC' | 'DESC' };
  start?: number;
  limit?: number;
};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  webinars?: M.Webinar[];
};

type OutputData = {
  webinars: M.Webinar[];
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    webinars: data.webinars || [],
  };
}

export const list = makeService<InputData, APIOutputData, OutputData>(
  (inputData, convertToSearchParams) => {
    const { filter, sort, start, limit, ticket } = inputData;

    return `webinar/?${convertToSearchParams({
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
      start,
      limit,
      ticket,
    })}`;
  },
  'api-v2-get',
  false,
  extractResponse,
);
