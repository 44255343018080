import React from 'react';

import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { nonNull } from 'utils/validators';

import { questionIsRequired } from '../i18nSharedReferences';

export const makeState = (initialValue: M.Location | null = null) => {
  const value = initialValue;

  const formElementState = makeFormElementState<M.Location | null>(null, [
    nonNull(questionIsRequired),
  ]);

  formElementState.units.value.setState(value);

  return formElementState;
};

export const useState = (initialValue?: M.Location | null) => {
  const [state] = React.useState(() => makeState(initialValue));
  return state;
};
