import { MainMenu } from 'components';
import {
  HeaderLinkEntry,
  HeaderParentEntry,
  HeaderNavigationEntry,
  profileConfig,
} from 'profileConfig';
import * as TS from 'types';

import { NavigationMenuProps } from './types';

function makeLinkEntry(entry: HeaderLinkEntry) {
  return ({ className }: { className?: string }) => {
    return (
      <MainMenu.Component
        RootEntry={MainMenu.makeRootLinkEntry({
          href: entry.href,
          useLabel: () => entry.label,
          isActive: entry.isActive,
        })}
        className={className}
      />
    );
  };
}

function makeParentEntry(entry: HeaderParentEntry) {
  return MainMenu.RootParentEntry.makeComponent({
    Label: MainMenu.RootParentEntry.LabelWithArrow.makeTextComponent(
      entry.label,
    ),
    childrenSections: [
      entry.subitems.map(x => ({
        kind: 'link',
        href: x.href,
        label: x.label,
        isActive: x.isActive,
      })),
    ],
  });
}

function makeNavigationEntry(entry: HeaderNavigationEntry) {
  if ('subitems' in entry) {
    return makeParentEntry(entry);
  }

  return makeLinkEntry(entry);
}

export const languageToNavigationMenus: Record<
  TS.Language,
  Array<React.FC<NavigationMenuProps>>
> = {
  ru: profileConfig.header.menu.items.ru.map(makeNavigationEntry),
  en: profileConfig.header.menu.items.en.map(makeNavigationEntry),
  es: profileConfig.header.menu.items.es.map(makeNavigationEntry),
};
