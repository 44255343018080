import { getHistory } from './history';

export function isHrefExternal(href: string) {
  return !href.startsWith('#') && !href.startsWith('/');
}

export function push(href: string) {
  if (isHrefExternal(href)) {
    window.open(href, '_blank', 'noopener,noreferrer');
  } else {
    getHistory()?.push(href);
  }
}
