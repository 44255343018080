import React, { useCallback } from 'react';

import { Preloader } from 'components';
import { API } from 'services';
import { AbstractStateUnit } from 'utils/State';
import { block, classnames } from 'utils/classname';

import './style.scss';

const b = block('icon-container');

interface HTMLElementMap {
  div: HTMLDivElement;
  button: HTMLButtonElement;
}

export type As = 'div' | 'button';
export type Size = 'xl' | 'l' | 'm' | 's' | 'm-half' | 's-half' | 'xs';
export type Variant = 'contained' | 'outlined';
export type BackgroundDarknessLevel = '0' | 1 | 2 | 3;

export type Props<TAs extends As = 'div'> = React.PropsWithChildren<
  {
    size: Size;
    as?: TAs;
    forwardedRef?: React.Ref<HTMLElementMap[TAs]>;
    color?: 'inherit' | 'fg-3';
    className?: string;
    variant?: Variant;
    backgroundDarknessLevel?: BackgroundDarknessLevel;
    callStateUnit?: AbstractStateUnit<API.CallState<unknown>>;
    disabled?: boolean;
  } & Pick<
    React.HTMLAttributes<HTMLElementMap[TAs]>,
    | 'title'
    | 'tabIndex'
    | 'onClick'
    | 'onMouseEnter'
    | 'onMouseLeave'
    | 'onMouseMove'
  >
>;

function IconContainer<TAs extends As = 'div'>({
  size,
  as = 'div' as TAs,
  forwardedRef,
  color = 'inherit',
  children,
  className,
  variant = 'contained',
  backgroundDarknessLevel = 1,
  callStateUnit,
  disabled,
  onClick,
  ...restProps
}: Props<TAs>) {
  const callState = callStateUnit?.useState();

  const sumDisabled = callState?.kind === 'pending' || disabled;

  const handleKeyDown: React.KeyboardEventHandler = useCallback(event => {
    const element = event.target;

    const shouldClick = !event.repeat && event.code === 'Enter';

    if (element instanceof HTMLElement && shouldClick) {
      event.preventDefault();
      element.click();
    }
  }, []);

  const handleClick: React.MouseEventHandler<HTMLElementMap[TAs]> = useCallback(
    event => {
      if (sumDisabled) {
        return;
      }

      onClick?.(event);
    },
    [sumDisabled, onClick],
  );

  return React.createElement(
    as,
    {
      className: classnames(
        b({
          size,
          color,
          variant,
          'background-darkness-level': backgroundDarknessLevel,
          disabled: sumDisabled,
        }),
        className,
      ),
      ref: forwardedRef,
      onKeyDown: handleKeyDown,
      ...restProps,
      tabIndex: !sumDisabled ? restProps.tabIndex : undefined,
      onClick: handleClick,
    },
    <>
      {children}
      {callState?.kind === 'pending' && (
        <Preloader.Component className={b('preloader')} size="xs" />
      )}
    </>,
  );
}

export const Component = React.memo(IconContainer);
