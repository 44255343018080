import { LocationObserverModal } from 'features/global';
import { routeTree } from 'pages/routeTree';
import { Routing } from 'services';
import * as TS from 'types';
import * as M from 'types/serverModels';

const config: Config = {
  GLOBALLAB: {
    title: 'ГлобалЛаб',
    header: {
      menu: {
        items: {
          en: [
            
          ],
          ru: [
            { label: 'Библиотека', href: routeTree.ru.project.list.getPath() },
            { label: 'Курсы', href: routeTree.ru.course.list.getPath() },
            {
              label: 'Магазин',
              href: routeTree.ru.shop.catalog.getPath(),
              isActive: route =>
                [
                  routeTree.ru.shop.getPath(),
                ].some(x => Routing.routeMatches(route, x)),
            },
            {
              label: 'Педагогу',
              subitems: [
                {
                  label: 'Как работать',
                  href: routeTree.ru.article.ARTICLE.getPath({
                    routeParams: {
                      ARTICLE: 'howtowork_for_tutors',
                    },
                  }),
                },
                {
                  label: 'Справочник',
                  href: routeTree.ru.article.ARTICLE.getPath({
                    routeParams: {
                      ARTICLE: 'spravochnik',
                    },
                  }),
                },
                {
                  label: 'Серии проектных заданий',
                  href: routeTree.ru.article.ARTICLE.getPath({
                    routeParams: {
                      ARTICLE: 'projseries',
                    },
                  }),
                },
                {
                  label: 'Актуально в этом месяце',
                  href: routeTree.ru.article.ARTICLE.getPath({
                    routeParams: {
                      ARTICLE: 'aktualno',
                    },
                  }),
                  isActive: route => {
                    // FIXME: пример как можно задать активное состояние ссылки через шаблон
                    // TIP: для сравнения с известными путями приложения можно воспользоваться 
                    // Routing.routeMatches(route, routeTree.ru.article.ARTICLE.getPath({ routeParams: { ARTICLE: <some-code-or-uuid> } })). 
                    // Пример использования есть у ссылки "Вебинары"
                    const routeParams =
                      routeTree.ru.article.ARTICLE.getRouteParams();

                    if (!routeParams) {
                      return false;
                    }

                    return routeParams.article.match(/recom.*/) !== null || routeParams.article.match(/aktualno.*/) !== null;
                  },
                },
                {
                  label: 'Методическая копилка',
                  href: routeTree.ru.article.ARTICLE.getPath({
                    routeParams: {
                      ARTICLE: 'methodlib',
                    },
                  }),
                },
                {
                  label: 'Вебинары',
                  href: routeTree.ru.webinar.list.schedular.getPath(),
                  isActive: route =>
                    [
                      routeTree.ru.webinar.list.getPath(),
                      routeTree.ru.webinar.page.getPath(),
                    ].some(x => Routing.routeMatches(route, x)),
                },
                {
                  label: 'Видеоматериалы',
                  href: routeTree.ru.article.ARTICLE.getPath({
                    routeParams: {
                      ARTICLE: 'videolib',
                    },
                  }),
                },
                {
                  label: 'Конкурсы и события',
                  href: routeTree.ru.article.ARTICLE.getPath({
                    routeParams: {
                      ARTICLE: 'contest',
                    },
                  }),
                },
                {
                  label: 'Статьи ГлобалЛаб',
                  href: routeTree.ru.article.ARTICLE.getPath({
                    routeParams: {
                      ARTICLE: 'gl_articles',
                    },
                  }),
                },
              ],
            },
          ],
          es: [
            
          ],
        },
      },
    },
    footer: {
      menu: {
        items: {
          en: [
            
          ],
          ru: [
            {
              label: 'Разделы',
              links: [
                {
                  label: 'Библиотека',
                  useHref: () => routeTree.ru.project.list.getPath(),
                },
                {
                  label: 'Участники',
                  useHref: () =>
                    routeTree.ru.community.search.members.getPath(),
                },
                {
                  label: 'Группы',
                  useHref: () => routeTree.ru.community.search.groups.getPath(),
                },
                {
                  label: 'Курсы',
                  useHref: () => routeTree.ru.course.list.getPath(),
                },
                {
                  label: 'Магазин',
                  useHref: () => routeTree.ru.shop.catalog.getPath(),
                },
                {
                  label: 'Вебинары',
                  useHref: () => routeTree.ru.webinar.list.schedular.getPath(),
                },
                {
                  label: 'Конкурсы и события',
                  useHref: () =>
                    routeTree.ru.article.ARTICLE.getPath({
                      routeParams: {
                        ARTICLE: 'contest',
                      },
                    }),
                },
                {
                  label: 'Сведения об образовательной организации',
                  useHref: () =>
                    routeTree.ru.article.ARTICLE.getPath({
                      routeParams: {
                        ARTICLE: 'eduorg_main_info',
                      },
                    }),
                },
              ],
            },
            {
              label: 'Помощь',
              links: [
                {
                  label: 'О ГлобалЛаб',
                  useHref: () =>
                    routeTree.LANG.article.ARTICLE.getPath({
                      routeParams: {
                        LANG: 'ru',

                        ARTICLE: 'aboutgl',
                      },
                    }),
                },
                {
                  label: 'Справочник',
                  useHref: () =>
                    routeTree.ru.article.ARTICLE.getPath({
                      routeParams: {
                        ARTICLE: 'tutstart',
                      },
                    }),
                },
                {
                  label: 'Партнёры',
                  useHref: () =>
                    routeTree.LANG.article.ARTICLE.getPath({
                      routeParams: {
                        LANG: 'ru',

                        ARTICLE: 'partners',
                      },
                    }),
                },
                {
                  label: 'Пользовательское соглашение',
                  useHref: () =>
                    routeTree.LANG.article.ARTICLE.getPath({
                      routeParams: {
                        LANG: 'ru',

                        ARTICLE: 'agreement',
                      },
                    }),
                },
                {
                  label: 'Политика обработки персональных данных',
                  useHref: () =>
                    routeTree.LANG.article.ARTICLE.getPath({
                      routeParams: {
                        LANG: 'ru',

                        ARTICLE: 'privacy',
                      },
                    }),
                },
                {
                  label: 'Контакты',
                  useHref: () =>
                    routeTree.LANG.article.ARTICLE.getPath({
                      routeParams: {
                        LANG: 'ru',

                        ARTICLE: 'our_contacts',
                      },
                    }),
                },
                {
                  label: 'Сообщить о проблеме',
                  useHref: () =>
                    LocationObserverModal.useCurrentLocationOpenHref(
                      LocationObserverModal.hashes.supportHashTag,
                    ),
                },
              ],
            },
            {
              label: 'Педагогу',
              links: [
                {
                  label: 'Как работать',
                  useHref: () =>
                    routeTree.ru.article.ARTICLE.getPath({
                      routeParams: {
                        ARTICLE: 'howtowork_for_tutors',
                      },
                    }),
                },
                {
                  label: 'Серии проектных заданий',
                  useHref: () =>
                    routeTree.ru.article.ARTICLE.getPath({
                      routeParams: {
                        ARTICLE: 'projseries',
                      },
                    }),
                },
                {
                  label: 'Актуально в этом месяце',
                  useHref: () =>
                    routeTree.ru.article.ARTICLE.getPath({
                      routeParams: {
                        ARTICLE: 'aktualno',
                      },
                    }),
                },
                {
                  label: 'Методическая копилка',
                  useHref: () =>
                    routeTree.ru.article.ARTICLE.getPath({
                      routeParams: {
                        ARTICLE: 'methodlib',
                      },
                    }),
                },
                {
                  label: 'Вебинары',
                  useHref: () => routeTree.ru.webinar.list.schedular.getPath(),
                },
                {
                  label: 'Видеоматериалы',
                  useHref: () =>
                    routeTree.ru.article.ARTICLE.getPath({
                      routeParams: {
                        ARTICLE: 'videolib',
                      },
                    }),
                },
                {
                  label: 'Статьи ГлобалЛаб',
                  useHref: () =>
                    routeTree.ru.article.ARTICLE.getPath({
                      routeParams: {
                        ARTICLE: 'gl_articles',
                      },
                    }),
                },
              ],
            },
          ],
          es: [
            
          ],
        },
      },
    },
    projectBook: {
      intro: {
        type: true,
        isIndividual: true,
        template: true,
      },
      page: {
        passport: {
          fields: {
            level: true,
          },
        },
        members: {
          supervisors: {
            addButton: true,
          },
          watchers: true,
          groups: true,
        },
        stages: {
          tasks: {
            self: true,
            board: true,
            gantt: true,
            table: true,
          },
          discussion: true,
          materials: {
            comments: true,
            type: {
              file: true,
              text: true,
              link: true,
              project: {
                self: true,
                constructor: {
                  tabs: {
                    accessTab: true,
                  },
                },
                copyProjectLinkButton: true,
              },
              mesch: false,
            },
          },
        },
        results: {
          self: true,
          download: true,
        },
        evaluation: {
          self: true,
          statistics: {
            self: true,
            LLM: true,
          },
          evaluation: true,
        },
        reflexion: true,
        history: true,
        notifications: true,
        recycleBin: true,
      },
      concurrency: true,
    },
  },
  GLOBALLAB_ONLINE: {
    title: 'Globallab',
    header: {
      menu: {
        items: {
          en: [
            { label: 'Inquiries', href: routeTree.en.project.list.getPath() },
            {
              label: 'Projects',
              href: routeTree.en['landing-page'].LANDING_PAGE_CODE.getPath({
                routeParams: {
                  LANDING_PAGE_CODE: 'project-books',
                },
              }),
            },
            {
              label: 'Prices',
              href: routeTree.en['landing-page'].LANDING_PAGE_CODE.getPath({
                routeParams: {
                  LANDING_PAGE_CODE: 'prices',
                },
              }),
            },
          ],
          ru: [
            
          ],
          es: [
            {
              label: 'Proyectos',
              href: routeTree.es.project.list.getPath(),
            },
          ],
        },
      },
    },
    footer: {
      menu: {
        items: {
          en: [
            {
              label: 'Resources',
              links: [
                {
                  label: 'Inquiries',
                  useHref: () => routeTree.en.project.list.getPath(),
                },
                {
                  label: 'Projects',
                  useHref: () =>
                    routeTree.en['landing-page'].LANDING_PAGE_CODE.getPath({
                      routeParams: {
                        LANDING_PAGE_CODE: 'project-books',
                      },
                    }),
                },
                {
                  label: 'Prices',
                  useHref: () =>
                    routeTree.en['landing-page'].LANDING_PAGE_CODE.getPath({
                      routeParams: {
                        LANDING_PAGE_CODE: 'prices',
                      },
                    }),
                },
              ],
            },
            {
              label: 'Help',
              links: [
                {
                  label: 'About GlobalLab',
                  useHref: () =>
                    routeTree.LANG.article.ARTICLE.getPath({
                      routeParams: {
                        LANG: 'en',

                        ARTICLE: 'about',
                      },
                    }),
                },
                {
                  label: 'User agreement',
                  useHref: () =>
                    routeTree.LANG.article.ARTICLE.getPath({
                      routeParams: {
                        LANG: 'en',

                        ARTICLE: 'user_agreement',
                      },
                    }),
                },
                {
                  label: 'Contacts',
                  useHref: () =>
                    routeTree.LANG.article.ARTICLE.getPath({
                      routeParams: {
                        LANG: 'en',

                        ARTICLE: 'contacts',
                      },
                    }),
                },
                {
                  label: 'Report a problem',
                  useHref: () =>
                    LocationObserverModal.useCurrentLocationOpenHref(
                      LocationObserverModal.hashes.supportHashTag,
                    ),
                },
              ],
            },
          ],
          ru: [
           
          ],
          es: [
            {
              label: 'Recursos',
              links: [
                {
                  label: 'Proyectos',
                  useHref: () => routeTree.es.project.list.getPath(),
                },
                {
                  label: 'Participantes',
                  useHref: () =>
                    routeTree.es.community.search.members.getPath(),
                },
                {
                  label: 'Grupos',
                  useHref: () => routeTree.es.community.search.groups.getPath(),
                },
                {
                  label: 'Cursos',
                  useHref: () => routeTree.es.course.list.getPath(),
                },
              ],
            },
            {
              label: 'Ayuda',
              links: [
                {
                  label: 'Acerca de Globallab',
                  useHref: () =>
                    routeTree.LANG.article.ARTICLE.getPath({
                      routeParams: {
                        LANG: 'es',

                        ARTICLE: 'about',
                      },
                    }),
                },
                {
                  label: 'Términos de uso',
                  useHref: () =>
                    routeTree.LANG.article.ARTICLE.getPath({
                      routeParams: {
                        LANG: 'es',

                        ARTICLE: 'user_agreement',
                      },
                    }),
                },
                {
                  label: 'Contactos',
                  useHref: () =>
                    routeTree.LANG.article.ARTICLE.getPath({
                      routeParams: {
                        LANG: 'es',

                        ARTICLE: 'contacts',
                      },
                    }),
                },
              ],
            },
          ],
        },
      },
    },
    projectBook: {
      intro: {
        type: true,
        isIndividual: true,
        template: true,
      },
      page: {
        passport: {
          fields: {
            level: true,
          },
        },
        members: {
          supervisors: {
            addButton: true,
          },
          watchers: true,
          groups: true,
        },
        stages: {
          tasks: {
            self: true,
            board: true,
            gantt: true,
            table: true,
          },
          discussion: true,
          materials: {
            comments: true,
            type: {
              file: true,
              text: true,
              link: true,
              project: {
                self: true,
                constructor: {
                  tabs: {
                    accessTab: true,
                  },
                },
                copyProjectLinkButton: true,
              },
              mesch: false,
            },
          },
        },
        results: {
          self: true,
          download: true,
        },
        evaluation: {
          self: true,
          statistics: {
            self: true,
            LLM: true,
          },
          evaluation: true,
        },
        reflexion: true,
        history: true,
        notifications: true,
        recycleBin: true,
      },
      concurrency: true,
    },
  },
  MESCH: {
    title: 'ГлобалЛаб',
    header: {
      menu: {
        items: {
          en: [
           
          ],
          ru: [
            
          ],
          es: [
            
          ],
        },
      },
    },
    footer: {
      menu: {
        items: {
          en: [
           
          ],
          ru: [
           
          ],
          es: [
            
          ],
        },
      },
    },
    projectBook: {
      intro: {
        type: false,
        isIndividual: true,
        template: false,
      },
      page: {
        passport: {
          fields: {
            level: false,
          },
        },
        members: {
          supervisors: {
            addButton: false,
          },
          watchers: false,
          groups: false,
        },
        stages: {
          tasks: {
            self: false,
            board: false,
            gantt: false,
            table: false,
          },
          discussion: true,
          materials: {
            comments: true,
            type: {
              file: true,
              text: false,
              link: true,
              project: {
                self: true,
                constructor: {
                  tabs: {
                    accessTab: false,
                  },
                },
                copyProjectLinkButton: false,
              },
              mesch: true,
            },
          },
        },
        results: {
          self: true,
          download: false,
        },
        evaluation: {
          self: false,
          statistics: {
            self: false,
            LLM: false,
          },
          evaluation: false,
        },
        reflexion: true,
        history: true,
        notifications: false,
        recycleBin: true,
      },
      concurrency: false,
    },
  },
  ALPHA2: {
    title: 'ГлобалЛаб',
    header: {
      menu: {
        items: {
          en: [
            
          ],
          ru: [
           
          ],
          es: [
           
          ],
        },
      },
    },
    footer: {
      menu: {
        items: {
          en: [
            
          ],
          ru: [
            
          ],
          es: [
            
          ],
        },
      },
    },
    projectBook: {
      intro: {
        type: false,
        isIndividual: true,
        template: false,
      },
      page: {
        passport: {
          fields: {
            level: false,
          },
        },
        members: {
          supervisors: {
            addButton: false,
          },
          watchers: false,
          groups: false,
        },
        stages: {
          tasks: {
            self: false,
            board: false,
            gantt: false,
            table: false,
          },
          discussion: true,
          materials: {
            comments: true,
            type: {
              file: true,
              text: false,
              link: true,
              project: {
                self: true,
                constructor: {
                  tabs: {
                    accessTab: false,
                  },
                },
                copyProjectLinkButton: false,
              },
              mesch: true,
            },
          },
        },
        results: {
          self: true,
          download: false,
        },
        evaluation: {
          self: false,
          statistics: {
            self: false,
            LLM: false,
          },
          evaluation: false,
        },
        reflexion: true,
        history: true,
        notifications: false,
        recycleBin: true,
      },
      concurrency: false,
    },
  },
};

export type HeaderParentEntry = { label: string; subitems: HeaderLinkEntry[] };
export type HeaderLinkEntry = {
  label: string;
  href: M.URL;
  isActive?(route: string): boolean;
};

export type HeaderNavigationEntry = HeaderParentEntry | HeaderLinkEntry;

export type FooterNavigationColumn = {
  label: string;
  links: FooterNavigationLink[];
};
export type FooterNavigationLink = {
  label: string;
  useHref: () => string;
};

type Config = Record<
  string,
  {
    title: string;
    header: { menu: { items: Record<TS.Language, HeaderNavigationEntry[]> } };
    footer: { menu: { items: Record<TS.Language, FooterNavigationColumn[]> } };
    projectBook: {
      intro: {
        type: boolean;
        isIndividual: boolean;
        template: boolean;
      };
      page: {
        passport: {
          fields: {
            level: boolean;
          };
        };
        members: {
          supervisors: {
            addButton: boolean;
          };
          watchers: boolean;
          groups: boolean;
        };
        stages: {
          tasks: {
            self: boolean;
            board: boolean;
            gantt: boolean;
            table: boolean;
          };
          discussion: boolean;
          materials: {
            comments: boolean;
            type: {
              file: boolean;
              text: boolean;
              link: boolean;
              project: {
                self: boolean;
                constructor: {
                  tabs: {
                    accessTab: boolean;
                  };
                };
                copyProjectLinkButton: boolean;
              };
              mesch: boolean;
            };
          };
        };
        results: {
          self: boolean;
          download: boolean;
        };
        evaluation: {
          self: boolean;
          statistics: {
            self: boolean;
            LLM: boolean;
          };
          evaluation: boolean;
        };
        reflexion: boolean;
        history: boolean;
        notifications: boolean;
        recycleBin: boolean;
      };
      concurrency: boolean;
    };
  }
>;

export const profileConfig = config[process.env.RAZZLE_PROFILE];
