import * as TS from 'types';
import * as M from 'types/serverModels';
import { Course } from 'utils/models';

import { makeService } from './utils';

type InputData = {
  course: M.Course['uuid'];
  lang: TS.Language;
  sort?: Partial<Record<keyof M.Course, 1 | -1>>;
};

type APIOutputData = M.CourseStreamServiceStream[];

type OutputData = M.CourseStreamServiceStreamOutput[];

function extractResponse(data: APIOutputData): OutputData {
  return data.map(x => ({
    ...x,
    tracks: x.tracks.map(y => ({
      ...y,
      objects: Course.track.addKeyToObjects(y.objects),
    })),
  }));
}

export const courseStreamList = makeService<
  InputData,
  APIOutputData,
  OutputData
>('course_stream_list', 'get', true, extractResponse);
