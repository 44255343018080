import {
  DisabledPresentationFileTypeIcon,
  EnabledPresentationFileTypeIcon,
  DisabledVideoFileTypeIcon,
  EnabledVideoFileTypeIcon,
  DisabledTestFileTypeIcon,
  EnabledTestFileTypeIcon,
  DisabledPrintFileTypeIcon,
  EnabledPrintFileTypeIcon,
  DisabledWebLinkFileTypeIcon,
  EnabledWebLinkFileTypeIcon,
  DisabledWebTextFileTypeIcon,
  EnabledWebTextFileTypeIcon,
} from 'shared/images/fileTypes';
import * as M from 'types/serverModels';

const icons = {
  presentation: {
    disabled: DisabledPresentationFileTypeIcon,
    enabled: EnabledPresentationFileTypeIcon,
  },
  video: {
    disabled: DisabledVideoFileTypeIcon,
    enabled: EnabledVideoFileTypeIcon,
  },
  test: {
    disabled: DisabledTestFileTypeIcon,
    enabled: EnabledTestFileTypeIcon,
  },
  print: {
    disabled: DisabledPrintFileTypeIcon,
    enabled: EnabledPrintFileTypeIcon,
  },
  web: {
    url: {
      disabled: DisabledWebLinkFileTypeIcon,
      enabled: EnabledWebLinkFileTypeIcon,
    },
    text: {
      disabled: DisabledWebTextFileTypeIcon,
      enabled: EnabledWebTextFileTypeIcon,
    },
  },
  interactive: {
    disabled: DisabledWebLinkFileTypeIcon,
    enabled: EnabledWebLinkFileTypeIcon,
  },
  project: {
    disabled: DisabledPresentationFileTypeIcon,
    enabled: EnabledPresentationFileTypeIcon,
  },
  mesch_eom: {
    disabled: DisabledPrintFileTypeIcon,
    enabled: EnabledPrintFileTypeIcon,
  },
  noop() {
    return null;
  },
};

export function getIcon(
  data: Partial<M.Unit | M.ProjectBookUnit> | undefined,
  variant: 'disabled' | 'enabled',
) {
  if (data?.type === undefined || icons[data.type] === undefined) {
    return icons.noop;
  }

  if (data.type === 'web') {
    return icons[data.type][data.url ? 'url' : 'text'][variant];
  }

  return icons[data.type][variant];
}
