import React, { useMemo, useEffect } from 'react';

import { Preloader } from 'components';
import { API, DataPrefetch, Page } from 'services';
import * as M from 'types/serverModels';
import { makeDerivedUnit, MappedState } from 'utils/State';
import { block } from 'utils/classname';

import { routeTree } from '../routeTree';
import * as features from './features';
import './style.scss';

const b = block('article-page');

const path = routeTree.LANG.article.ARTICLE.getPath();

type PrefetchedViewData =
  | {
      kind: 'batch';
      articleData: API.OutputDataOf<typeof API.services.article.get>;
      batchChildrenData: API.OutputDataOf<typeof API.services.article.list>;
    }
  | {
      kind: 'article';
      articleData: API.OutputDataOf<typeof API.services.article.get>;
    };

type ViewData =
  | {
      kind: 'batch';
      path: M.Batch[];
      batch: M.Batch;
      children: (M.Batch | M.Article)[];
    }
  | {
      kind: 'article';
      path: M.Batch[];
      article: M.Article;
      linkedArticles: M.Article[];
    };

const viewDataPrefetcher = DataPrefetch.makeDataPrefetcher(
  'Article',
  path + '/:rest*',
  async ({ article }): Promise<PrefetchedViewData> => {
    const articleData = await API.services.article.get.callPromised({
      uuid_or_code: article,
    });

    switch (articleData.article.type) {
      case 'batch': {
        const batchChildrenData = await API.services.article.list.callPromised({
          filter: {
            parent: articleData.article.uuid,
          },
        });

        return {
          kind: 'batch',
          articleData,
          batchChildrenData,
        };
      }
      case 'article': {
        return {
          kind: 'article',
          articleData,
        };
      }
    }
  },
);

function ArticlePage() {
  const params = routeTree.LANG.article.ARTICLE.useRouteParams();

  const uuidOrCode = params && params.article;

  const prefetchedViewData = viewDataPrefetcher.getData();

  const articleDataCallStateUnit = API.services.article.get.useCallStateUnit(
    prefetchedViewData !== DataPrefetch.notFetched
      ? prefetchedViewData.articleData
      : DataPrefetch.notFetched,
  );
  const batchChildrenCallStateUnit = API.services.article.list.useCallStateUnit(
    prefetchedViewData !== DataPrefetch.notFetched &&
      'batchChildrenData' in prefetchedViewData
      ? prefetchedViewData.batchChildrenData
      : DataPrefetch.notFetched,
  );
  const menuCallStateUnit = API.services.article.list.useCallStateUnit();

  const viewCallStateUnit = useMemo(
    () =>
      makeDerivedUnit(
        articleDataCallStateUnit,
        batchChildrenCallStateUnit,
      ).getUnit(
        (
          articleDataCallState,
          batchChildrenCallState,
        ): API.CallState<ViewData> => {
          if (articleDataCallState.kind !== 'successful') {
            return articleDataCallState;
          }

          switch (articleDataCallState.data.article.type) {
            case 'batch': {
              if (batchChildrenCallState.kind !== 'successful') {
                return batchChildrenCallState;
              }

              return {
                kind: 'successful',
                data: {
                  kind: 'batch',
                  path: articleDataCallState.data.path,
                  batch: articleDataCallState.data.article,
                  children: batchChildrenCallState.data.articles,
                },
              };
            }
            case 'article': {
              return {
                kind: 'successful',
                data: {
                  kind: 'article',
                  path: articleDataCallState.data.path,
                  article: articleDataCallState.data.article,
                  linkedArticles: articleDataCallState.data.linkedArticles,
                },
              };
            }
          }
        },
      ),
    [articleDataCallStateUnit, batchChildrenCallStateUnit],
  );

  const viewCallState = viewCallStateUnit.useState();
  const menuCallState = menuCallStateUnit.useState();

  const callArticleData = API.services.article.get.useCall(
    articleDataCallStateUnit,
  );
  const callBatchChildren = API.services.article.list.useCall(
    batchChildrenCallStateUnit,
  );
  const callMenu = API.services.article.list.useCall(menuCallStateUnit);

  useEffect(() => {
    if (viewCallState.kind === 'successful') {
      window.scrollTo(0, 0);
    }
  }, [viewCallState]);

  useEffect(() => {
    let parent: string | undefined | null = null;

    const callback = (
      callState: MappedState<typeof articleDataCallStateUnit>,
    ) => {
      switch (callState.kind) {
        case 'successful': {
          if (parent !== callState.data.article.parent) {
            parent = callState.data.article.parent;
            callMenu({
              filter: parent
                ? {
                    parent,
                  }
                : {
                    $or: [
                      { parent: { $exists: false } },
                      { parent: { $eq: '' } },
                    ],
                  },
            });
          }

          switch (callState.data.article.type) {
            case 'batch': {
              if (viewDataPrefetcher.getData() === DataPrefetch.notFetched) {
                callBatchChildren({
                  filter: {
                    parent: callState.data.article.uuid,
                  },
                });
              }

              break;
            }
          }
        }
      }
    };

    callback(articleDataCallStateUnit.getState());

    return articleDataCallStateUnit.subscribe({
      name: 'additional-data-caller',
      callback,
    });
  }, [articleDataCallStateUnit, callBatchChildren, callMenu]);

  useEffect(() => {
    if (viewDataPrefetcher.getData() !== DataPrefetch.notFetched) {
      return () => {
        viewDataPrefetcher.deleteData();
      };
    }

    if (!uuidOrCode) {
      return;
    }

    callArticleData({ uuid_or_code: uuidOrCode });
  }, [uuidOrCode, articleDataCallStateUnit, callArticleData]);

  useEffect(() => {
    return () => {
      viewDataPrefetcher.deleteData();
    };
  }, [uuidOrCode]);

  return (
    <div className={b()}>
      <div className={b('side-menu')}>
        {API.renderCallState(menuCallState, {
          successful: ({ data }) => {
            return <features.TabsMenu.Component items={data.articles} />;
          },
          pending: () => <Preloader.Component size="xs" />,
        })}
      </div>
      <div className={b('card')}>
        {API.renderCallState(viewCallState, {
          successful: ({ data }) => {
            return (
              <>
                <features.BreadCrumbs.Component
                  className={b('breadcrumbs')}
                  path={data.path}
                />
                {(() => {
                  switch (data.kind) {
                    case 'batch': {
                      return (
                        <features.BatchView.Component
                          batch={data.batch}
                          children={data.children}
                        />
                      );
                    }
                    case 'article': {
                      return (
                        <features.ArticleView.Component
                          article={data.article}
                          linkedArticles={data.linkedArticles}
                        />
                      );
                    }
                    default: {
                      return null;
                    }
                  }
                })()}
              </>
            );
          },
          pending: () => <Preloader.Component size="xs" />,
        })}
      </div>
    </div>
  );
}

export const Component = Page.makePage({
  path,
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(ArticlePage),
  features: Object.values(features).map(x => x.feature),
});
