import * as TS from 'types';
import * as M from 'types/serverModels';
import { makePrimaryUnit } from 'utils/State';
import { isAccessDenied } from 'utils/types/guards';

import { Kind, State } from '../types';
import * as Date from './Date';
import * as Draw from './Draw';
import * as File from './File';
import * as Image from './Image';
import * as Map from './Map';
import * as MultiChoice from './MultiChoice';
import * as Number from './Number';
import * as Probe from './Probe';
import * as SingleChoice from './SingleChoice';
import * as Sound from './Sound';
import * as String from './String';
import * as Text from './Text';
import * as Video from './Video';

export {
  Date,
  Draw,
  File,
  Image,
  Map,
  MultiChoice,
  Number,
  Probe,
  SingleChoice,
  Sound,
  String,
  Text,
  Video,
};

const makeQuestionState = (
  kind: Kind,
  question: M.Question,
  questionnaire: Pick<M.QuestionnaireData, 'answers' | 'custom'> | null,
) => {
  const answer = questionnaire?.answers?.[question.uuid];
  const initialValue = !isAccessDenied(answer) ? (answer as any) : undefined;

  switch (question.type) {
    case 'text':
      return Text.makeState(question, initialValue);
    case 'string':
      return String.makeState(question, initialValue);
    case 'file':
      return File.makeState(question, initialValue);
    case 'date':
      return Date.makeState(question, initialValue);
    case 'draw':
      return Draw.makeState(question, initialValue);
    case 'map':
      return Map.makeState(question, initialValue, kind === 'form');
    case 'sound':
      return Sound.makeState(question, initialValue);
    case 'number':
      return Number.makeState(question, initialValue);
    case 'probe':
      return Probe.makeState(question, initialValue);
    case 'image':
      return Image.makeState(question, initialValue);
    case 'video':
      return Video.makeState(question, initialValue);
    case 'multi_choice':
      return MultiChoice.makeState(
        question,
        initialValue,
        questionnaire?.custom?.[question.uuid],
      );
    case 'single_choice':
      return SingleChoice.makeState(
        question,
        initialValue,
        questionnaire?.custom?.[question.uuid],
      );
  }
};

export const makeQuestionsState = (
  kind: Kind,
  questions: M.Question[],
  questionnaire: Pick<M.QuestionnaireData, 'answers' | 'custom'> | null = null,
): TS.QuestionsState => {
  return questions.reduce<TS.QuestionsState>((acc, question) => {
    if (acc[question.type]) {
      return {
        ...acc,
        [question.type]: {
          ...acc[question.type],
          [question.uuid]: makeQuestionState(kind, question, questionnaire),
        },
      };
    }
    return {
      ...acc,
      [question.type]: {
        [question.uuid]: makeQuestionState(kind, question, questionnaire),
      },
    };
  }, {} as TS.QuestionsState);
};

export const makeState = (
  kind: Kind,
  questions: M.Question[],
  questionnaire: M.QuestionnaireData | null = null,
): State => {
  return {
    questionnaire: makePrimaryUnit(questionnaire),
    questions: makeQuestionsState(kind, questions, questionnaire),
  };
};
