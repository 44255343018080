import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = {};

type QueryInputData = {
  lang: TS.Language;
  query: {
    substring?: string;
    langs?: string[];
    features?: string[];
    ages?: string[];
    subjects?: string[];
    classification?: string[];
  };
  sort?: { property: keyof M.Project; direction: 'ASC' | 'DESC' };
  start?: number;
  limit?: number;
};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  projects?: M.ProjectListProject[];
  count?: number;
};

type OutputData = {
  projects: M.ProjectListProject[];
  count: number;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projects: data.projects || [],
    count: data.count ?? -1,
  };
}

export const list = makeService<InputData, APIOutputData, OutputData>(
  (inputData, convertToSearchParams) => {
    const { lang, query, sort, start, limit } = inputData;

    return `project/search/?${convertToSearchParams({
      lang,
      query: JSON.stringify(query),
      sort: JSON.stringify(sort),
      start,
      limit,
    })}`;
  },
  'api-v2-get',
  false,
  extractResponse,
);
