import React, { useEffect } from 'react';

import * as API from 'services/API';

type Props = {};

function TokenRefresh({}: Props) {
  const authData = API.useAuthData();

  const callStateUnit = API.services.oauth2.token.post.useCallStateUnit();
  const call = API.services.oauth2.token.post.useCall(callStateUnit);

  useEffect(() => {
    if (!authData) return;

    const { refreshToken, expiresIn } = authData;

    const expiresInWithGap = expiresIn - 10 * 1000;

    const timeoutId = setTimeout(
      () => {
        call({
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
        });
      },
      expiresInWithGap < 0 ? 0 : expiresInWithGap,
    );

    return () => {
      clearTimeout(timeoutId);
    };
  }, [authData, call]);

  useEffect(() => {
    return callStateUnit.subscribe({
      name: 'updating-auth-data',
      callback: async callState => {
        switch (callState.kind) {
          case 'successful': {
            const { data } = callState;
            API.setAuthData({
              accessToken: data.access_token,
              refreshToken: data.refresh_token,
              expiresIn: data.expires_in * 1000,
            });
            break;
          }
          case 'error': {
            try {
              await API.services.logout.callPromised({});
              API.setAuthData(null);
            } catch {
              API.setAuthData(null);
            }
            break;
          }
        }
      },
    });
  }, [callStateUnit]);

  return null;
}

export const Component = React.memo(TokenRefresh);
