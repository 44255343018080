import { FilterQuery } from 'mongodb';

import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = {};

type QueryInputData = {
  filter?: FilterQuery<M.Project>;
  sort?: { property: keyof M.Project; direction: 'ASC' | 'DESC' };
  start?: number;
  limit?: number;
};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  projects?: M.ProjectListProject[];
  count?: number;
};

type OutputData = {
  projects: M.ProjectListProject[];
  count: number;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projects: data.projects || [],
    count: data.count ?? -1,
  };
}

export const list = makeService<InputData, APIOutputData, OutputData>(
  (inputData, convertToSearchParams) => {
    const { filter, sort, start, limit } = inputData;

    return `project/?${convertToSearchParams({
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
      start,
      limit,
    })}`;
  },
  'api-v2-get',
  false,
  extractResponse,
);
