import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid?: M.Course['uuid'];
};

type APIOutputData = M.UserData[];

type OutputData = {
  users: M.UserXShort[];
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    users: data,
  };
}

export const userCourseList = makeService<InputData, APIOutputData, OutputData>(
  'user_course_list',
  'get',
  true,
  extractResponse,
);
