import { makeFeature } from 'services/Feature';

export const feature = makeFeature({
  name: 'BatchView',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "batch-view" */
      './Component'
    ),
  i18nData: null,
  i18nSharedReferences: null,
  subfeatures: [],
  deepPrefetched: true,
});

export const Component = feature.Component;
