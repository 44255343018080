import { SetOptional } from 'type-fest';

import { State } from '../types';
import { makeCheckCallStateUnit } from './makeCheckCallStateUnit';
import { makePurchaseTrialCallStateUnit } from './makePurchaseTrialCallStateUnit';

export function makeCheck<
  T extends SetOptional<State, 'callStateUnit' | 'purchaseTrialCallStateUnit'>,
>(
  state: T,
): T & {
  callStateUnit: ReturnType<typeof makeCheckCallStateUnit>;
  purchaseTrialCallStateUnit: ReturnType<typeof makePurchaseTrialCallStateUnit>;
} {
  const callStateUnit = makeCheckCallStateUnit();
  const purchaseTrialCallStateUnit = makeCheckCallStateUnit();

  return {
    ...state,
    callStateUnit: state.callStateUnit || callStateUnit,
    purchaseTrialCallStateUnit:
      state.purchaseTrialCallStateUnit || purchaseTrialCallStateUnit,
  };
}
