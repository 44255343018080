import * as R from 'ramda';
import React from 'react';

import { IconContainer } from 'components';
import { ReactComponent as ExtraIcon } from 'shared/images/extra.svg';

import { ProvidedProps } from '../types';

type OwnProps = { useTitle(): string };
type Props = ProvidedProps & OwnProps;

function IconContainerButton({ forwardedRef, useTitle, onClick }: Props) {
  return (
    <IconContainer.Component
      as="button"
      forwardedRef={forwardedRef}
      size="s"
      title={useTitle()}
      onClick={onClick}
    >
      <ExtraIcon />
    </IconContainer.Component>
  );
}

export function makeComponent(ownProps: OwnProps) {
  return React.memo(R.partialObject(IconContainerButton, ownProps));
}
