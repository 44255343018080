import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = {
  uuid: M.Project['uuid'];
};

type QueryInputData = { lang?: TS.Language };

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  countries?: M.ProjectGeo;
};

type OutputData = {
  countries: M.ProjectGeo;
};

function extractResponse(data: APIOutputData): OutputData {
  if (!data.countries) {
    throw Error('undefined countries');
  }

  return {
    countries: data.countries,
  };
}

export const list = makeService<InputData, APIOutputData, OutputData>(
  ({ uuid, lang }, convertToSearchParams) =>
    `project/${uuid}/geo/?${convertToSearchParams({ lang })}`,
  'api-v2-get',
  false,
  extractResponse,
);
