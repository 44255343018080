import { hydrate, render } from 'react-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { API, PageFeaturesRegistry, Feature, Routing } from 'services';
import { getCookie } from 'utils/Cookie';

import App from './App';
import { profileConfig } from './profileConfig';

const accessToken = getCookie('ticket');
const refreshToken = getCookie('refresh_token');
const expiresAt = getCookie('expires_at');

if (accessToken && refreshToken && expiresAt) {
  API.setAuthData({
    accessToken,
    refreshToken,
    expiresIn: Number(expiresAt) * 1000 - Date.now(),
  });
} else {
  API.setAuthData(null);
}

const pathFeatures = PageFeaturesRegistry.getFeaturesForPath(
  window.location.pathname.replace(
    new RegExp(`^${process.env.RAZZLE_PATH_BASENAME}`),
    '',
  ),
);

const clientOnlyFlatFeaturesSet = new Set<Feature.Feature<any>['name']>();

Feature.flattenFeatures(pathFeatures, feature => {
  if (feature.clientOnly) {
    clientOnlyFlatFeaturesSet.add(feature.name);

    Feature.flattenFeatures(feature.subfeatures).forEach(x => {
      clientOnlyFlatFeaturesSet.add(x.name);
    });
  }
});

const prefetchedFeatures = pathFeatures.reduce<Feature.Feature<any>[]>(
  (acc, x) => {
    if (x.deepPrefetched) {
      const prefetchedFlatSubFeatures = Feature.flattenFeatures(
        x.subfeatures,
      ).filter(x => !clientOnlyFlatFeaturesSet.has(x.name));

      return [...acc, x, ...prefetchedFlatSubFeatures];
    }

    if (x.prefetched) {
      return [...acc, x];
    }

    return acc;
  },
  [],
);

Promise.all(prefetchedFeatures.map(x => x.loadComponentModule())).then(() => {
  const renderer = (() => {
    switch (process.env.RAZZLE_BUILD_TYPE) {
      case 'iso': {
        return hydrate;
      }
      case 'spa': {
        return render;
      }
    }
  })();

  renderer?.(
    <HelmetProvider>
      <Routing.OriginContext.Provider
        originURL={new URL(process.env.RAZZLE_ORIGIN)}
      >
        <BrowserRouter basename={process.env.RAZZLE_PATH_BASENAME}>
          <Helmet>
            <title>{profileConfig.title}</title>
          </Helmet>
          <App />
        </BrowserRouter>
      </Routing.OriginContext.Provider>
    </HelmetProvider>,
    document.getElementById('root'),
  );
});

if ((module as any).hot) {
  (module as any).hot.accept();
}
