import React from 'react';

import i18nData from 'features/project/Constructor/i18n.json';
import { TextEditor } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';

import { FormExtensionProps } from '../../../types';
import { StateInstance } from '../types';

function FormExtension({ index, instance }: FormExtensionProps<StateInstance>) {
  const text = I18n.useText(i18nData);

  return (
    <TextEditor.Component
      key={index}
      formElementState={instance.text}
      placeholder={text.steps.shared.materials.kinds.text.placeholder}
      errorRows={1}
    />
  );
}

export const Component = React.memo(FormExtension);
