import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type BodyInputData = {
  feature: NonNullable<M.Product['trial_for_feature']>;
};

type InputData = BodyInputData;

type APIOutputData = {
  purchase?: M.Purchase;
};

type OutputData = {
  purchase?: M.Purchase;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    purchase: data.purchase,
  };
}

export const post = makeService<InputData, APIOutputData, OutputData>(
  () => `purchase/trial/`,
  'api-v2-post',
  false,
  extractResponse,
  {
    contentType: 'application/x-www-form-urlencoded',
    extractBodyInput: input => {
      const { ticket, ...bodyInput } = input;

      return bodyInput;
    },
  },
);
