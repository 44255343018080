import { Merge } from 'type-fest';

import { Language } from 'types';
import * as M from 'types/serverModels';
import { Course } from 'utils/models';

import { makeService } from './utils';

type InputData = {
  course: M.Course['uuid'];
  lang: Language;
};

/**
 * 0 - не будет, поскольку пользователь не авторизован
 * 1 - не будет, поскольку не присоединился к курсу
 * 2 - не будет, поскольку поток пользователя неактивен
 * 3 - будет
 */
type TrackOpenState = 0 | 1 | 2 | 3;

type APIOutputData = {
  currentStream: M.CourseStreamServiceStream | null;
  trackOpenState: TrackOpenState;
  canJoin: boolean;
  progress?: M.StreamProgress;
};

type OutputData = Merge<
  APIOutputData,
  {
    currentStream: M.CourseStreamServiceStreamOutput | null;
  }
>;

function extractResponse(data: APIOutputData): OutputData {
  return {
    ...data,
    currentStream: data.currentStream && {
      ...data.currentStream,
      tracks: data.currentStream.tracks.map(x => ({
        ...x,
        objects: Course.track.addKeyToObjects(x.objects),
      })),
    },
  };
}

export const courseStreamCurrent = makeService<
  InputData,
  APIOutputData,
  OutputData
>('course_stream_current', 'get', true, extractResponse);
