import React from 'react';

import { routeTree } from 'pages/routeTree';
import { Page } from 'services';

import * as features from './features';

type Props = Omit<features.Questionnaire.Props, 'withHelmet'>;

function Questionnaire(props: Props) {
  return <features.Questionnaire.Component withHelmet {...props} />;
}

export const path = routeTree.LANG.project.list.PROJECT.form.getPath();

export const Component = Page.makePage({
  path,
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(Questionnaire) as typeof Questionnaire,
  features: Object.values(features).map(x => x.feature),
}) as typeof Questionnaire;
