import * as M from 'types/serverModels';

export const hasLicense = (user: Pick<M.User, '_licenses'>) =>
  Array.isArray(user._licenses) && user._licenses.length > 0;

export const hasChosenLicense = (
  user: Pick<M.User, '_licenses'>,
  license: M.License | null,
) => {
  return (
    license !== null &&
    hasLicense(user) &&
    user._licenses!.some(
      userLicense => license.product.uuid === userLicense.product_uuid,
    )
  );
};

export const getUpToDateFeatures = (
  data: M.StoreUserPurchaseListPurchase[],
) => {
  const dateNow = Date.now();

  return data.reduce<M.StoreUserPurchaseListFeature[]>((acc, x) => {
    if (x.status !== 1 || Date.parse(x._expiresOn) < dateNow) {
      return acc;
    }

    return [...acc, ...x.product.features];
  }, []);
};

export const getActivatableFeatures = (
  data: M.StoreUserPurchaseListPurchase[],
) => {
  const upToDateFeatures = getUpToDateFeatures(data);

  const activatableFeatures = upToDateFeatures
    .filter(x => x.options?.kind === 'ability' && !x.options?.hidden)
    .reduce<Record<string, M.StoreUserPurchaseListFeature>>((acc, x) => {
      return {
        ...acc,
        [x.code]: {
          ...x,
          qty: (acc[x.code]?.qty || 0) + (x.qty > 0 ? x.qty : 0),
        },
      };
    }, {});

  return Object.values(activatableFeatures).filter(x => x.qty >= 1);
};
