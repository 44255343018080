import * as DataPrefetch from 'services/DataPrefetch';
import { makePrimaryUnit, PrimaryStateUnit, UnitDebugData } from 'utils/State';

import { CallState } from './types';

export function makeCallStateUnit<T>(
  initialData?: T | typeof DataPrefetch.notFetched,
  debugData?: UnitDebugData,
): PrimaryStateUnit<CallState<T>> {
  return makePrimaryUnit<CallState<T>>(
    initialData !== undefined && initialData !== DataPrefetch.notFetched
      ? { kind: 'successful', data: initialData }
      : { kind: 'initial' },
    debugData,
  );
}
