import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = {
  uuid_or_code:
    | M.Batch['code']
    | M.Batch['uuid']
    | M.Article['code']
    | M.Article['uuid'];
};

type QueryInputData = {};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  article?: M.Batch | M.Article;
  linked_articles?: M.Article[];
  path?: M.Batch[];
};

type OutputData = {
  article: M.Batch | M.Article;
  linkedArticles: M.Article[];
  path: M.Batch[];
};

function extractResponse(data: APIOutputData): OutputData {
  if (!data.article) {
    throw Error('undefined article');
  }

  return {
    article: data.article,
    linkedArticles: data.linked_articles || [],
    path: data.path || [],
  };
}

export const get = makeService<InputData, APIOutputData, OutputData>(
  ({ uuid_or_code }) => `article/${uuid_or_code}/`,
  'api-v2-get',
  false,
  extractResponse,
);
