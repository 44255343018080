import { useMemo, useLayoutEffect, useEffect } from 'react';

import * as M from 'types/serverModels';
import { makeUUID } from 'utils/UUID';

let scrollPossibilityIntentions: {
  [key: M.UUID]: 'disable' | '';
} = {};

// TIP: some components are rendered to body, position: fixed breaks their positioning
const root =
  process.env.BUILD_TARGET === 'client'
    ? document.getElementById('root')
    : null;

function disable() {
  if (!root || root.dataset['scrollPosition']) {
    return;
  }

  const scrollPosition = window.scrollY;

  root.dataset['scrollPosition'] = `${window.scrollY}`;

  root.style.overflow = 'hidden';
  root.style.position = 'fixed';
  root.style.top = `-${scrollPosition}px`;
  root.style.left = '0';
  root.style.right = '0';
}

function enable() {
  if (!root || !root.dataset['scrollPosition']) {
    return;
  }

  root.style.overflow = '';
  root.style.position = '';
  root.style.top = '';
  root.style.left = '';
  root.style.right = '';

  window.scrollTo(0, Number(root.dataset['scrollPosition']));

  root.dataset['scrollPosition'] = '';
}

function update() {
  const shouldDisable = Object.values(scrollPossibilityIntentions).some(x => {
    return x === 'disable';
  })
    ? 'disable'
    : '';

  if (shouldDisable) {
    disable();
  } else {
    enable();
  }
}

export function useSetScroll(isDisabled: boolean) {
  const uuid = useMemo(() => {
    return makeUUID();
  }, []);

  useLayoutEffect(() => {
    scrollPossibilityIntentions = {
      ...scrollPossibilityIntentions,
      [uuid]: isDisabled ? 'disable' : '',
    };

    update();
  }, [isDisabled, uuid]);

  useEffect(
    () => () => {
      const { [uuid]: key, ...rest } = scrollPossibilityIntentions;

      scrollPossibilityIntentions = { ...rest };

      update();
    },
    [uuid],
  );

  return useMemo(() => {
    return {
      setScrollPosition(scrollPosition: `${number}`) {
        if (!root || !root.dataset['scrollPosition']) {
          return;
        }

        root.style.top = scrollPosition;
        root.dataset['scrollPosition'] = scrollPosition;
      },
    };
  }, []);
}
