import * as M from 'types/serverModels';

export function getFlatObjects(
  trackObjects: M.CourseStreamStatTrackObjectOutput[],
) {
  return trackObjects.reduce<M.CourseStreamStatTrackObjectOutput[]>(
    (acc, x): M.CourseStreamStatTrackObjectOutput[] => {
      switch (x.type) {
        case 'section':
        case 'subsection': {
          return [...acc, x, ...getFlatObjects(x.children || [])];
        }
        case 'project':
        case 'unit':
        case 'quiz': {
          return [...acc, x];
        }

        default: {
          return acc;
        }
      }
    },
    [],
  );
}
